export default {
  CASH_REGISTER_READINGS_PAYMENT_ITEM_LIST: "Liste des paiements",
  ADD_CASH_REGISTER_READING_PAYMENT_ITEM: "Ajouter une paiement",
  CASH_REGISTER_READING_PAYMENT_ITEM_ADDED: "paiement ajoutée",
  EDIT_CASH_REGISTER_READING_PAYMENT_ITEM: "Modifier le paiement",
  CASH_REGISTER_READING_PAYMENT_ITEM_UPDATED: "paiement mise à jour",
  DELETE_THIS_CASH_REGISTER_READING_PAYMENT_ITEM: "Supprimer ce paiement ?",
  CASH_REGISTER_READING_PAYMENT_ITEM_DELETED: "paiement supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  AMOUNT: "Montant",
  TITLE: "Titre",
};
