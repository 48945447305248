export default {
  CASH_REGISTER_READINGS_EXPENSE_ITEM_LIST: "Liste des dépenses",
  ADD_CASH_REGISTER_READING_EXPENSE_ITEM: "Ajouter une dépense",
  CASH_REGISTER_READING_EXPENSE_ITEM_ADDED: "Dépense ajoutée",
  EDIT_CASH_REGISTER_READING_EXPENSE_ITEM: "Modifier la dépense",
  CASH_REGISTER_READING_EXPENSE_ITEM_UPDATED: "Dépense mise à jour",
  DELETE_THIS_CASH_REGISTER_READING_EXPENSE_ITEM: "Supprimer cette dépense?",
  CASH_REGISTER_READING_EXPENSE_ITEM_DELETED: "Dépense supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  AMOUNT: "Montant",
  TITLE: "Titre",
};
