export default {
  CASH_REGISTER_READINGS_INVOICE_ITEM_LIST: "Liste des Factures",
  ADD_CASH_REGISTER_READING_INVOICE_ITEM: "Ajouter une Facture",
  CASH_REGISTER_READING_INVOICE_ITEM_ADDED: "Facture ajoutée",
  EDIT_CASH_REGISTER_READING_INVOICE_ITEM: "Modifier la Facture",
  CASH_REGISTER_READING_INVOICE_ITEM_UPDATED: "Facture mise à jour",
  DELETE_THIS_CASH_REGISTER_READING_INVOICE_ITEM: "Supprimer cette Facture?",
  CASH_REGISTER_READING_INVOICE_ITEM_DELETED: "Facture supprimée",
  STARTS_AT: "Début",
  ENDS_AT: "Fin",
  CREATED_BY: "Crée par",
  UPDATED_BY: "Modifieé par",
  UPDATED_BY: "Modifieé par",
  AMOUNT: "Montant",
  TITLE: "Titre",
};
